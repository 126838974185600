.follow-us {

    .social {
        margin: 10px;
    }

    .follow,
    a {
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    a {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        /* border: 1px solid; */
        // background: #365677;
        color: #fff;
        cursor: pointer;
    }

    p {
        margin-left: 8px;
    }
}