.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
    // padding: 18px 15px;
    .logo {
        margin: 0px 10px;
        font-family: open-sans;
        text-align: left;

        div {
            color: red;
            font-size: 30px;
            font-weight: 600;
        }

        span {
            font-size: 15px;
            margin-left: 25px;
        }

    }

    .about-us {
        display: flex;
        align-items: center;

        div {
            margin-right: 14px;
            font-weight: 600;
            color: #5d5dd7;
        }

        .call {
            background-color: #365677;
            color: #fff;
            // width: 175px;
            height: 40px;
            display: flex;
            padding: 10px;
            border-radius: 14px;
        
            img{
                color: #fff;
            }
            a {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
    margin-left: 10px;

            }
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 300px){
    .about-us{
        div{
            margin-right: 0px !important;
        }
        .call{ 
            img{
                display:none;
            }
        }
    }

}