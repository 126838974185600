.FAQ{
    padding: 20px 40px;
    .p-accordion-tab {
        margin: 10px 0px;
        span.p-accordion-header-text {
            color: #666666;
            font-size: 13px;
            text-align: left;
        }
        .p-accordion-content {
            font-size: 12px;
            text-align: left;
            line-height: 21px;
        }

    }
    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
      box-shadow: none !important;
    }
    span.p-accordion-toggle-icon.pi.pi-chevron-right,.pi.pi-chevron-down {
        font-size: 12px;
    }
}