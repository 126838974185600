.dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .cards {
        cursor: pointer;
        width: 30%;
        border: 1px solid #e6e6e6;
        margin: 11px;
        padding: 10px 0px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: transform .2s;
    }

    .cards:hover {
        transform: scale(1.1);
    }
}

.cover-image {
    width: 100%;
    height: 450px;
    margin: 4px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.headers {
    font-weight: 600;
    width: 65%;
    margin: 60px 0px 0px 20px;
    text-align: left;
    font-size: 35px;
    color: #000;
}

.header-text {
    color: #96371d;
    text-align: left;
    margin: 10px 15px;
    width: 60%;
}

.why {
    padding: 75px;
    background: #f5f4f4;
    font-family: open-sans;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
   line-height: 22px;
}
.milestones-cards{
    background-image: url(../../assets/images/milestones.png);
    padding: 20px;
    .left,.right{
        width:100%;
        font-weight: 600;
        font-size: 12px;
        margin: 30px;
        line-height: 21px;
        }
    .left{
        div{
            width: 50%;
            text-align: left;
        }
    }
    .right{
        div{
            position: relative;
            left: 49%;
            width: 45%;
            text-align: left;
        }
    }

}
.milestones {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    

    .milestone {
        max-width: 17%;
        margin: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 280px;
        // margin: 20px 0px;

        .year {
            background: white;
            height: 80px;
            width: 80px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            margin-bottom: 25px;
        }

        .content {
            font-size: 13px;
            font-weight: 600;
            text-align: left;
            line-height: 22px;
        }
    }
}

.about {
    font-family: open-sans;
    padding: 30px;
    background-color: #262627;
    color: #ffffff;

    h5 {
        text-align: left;
        font-weight: 500;
        font-size: 14px;
    }

    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

}

.partners {
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

    marquee {
        img {
            width: 165px;
            height: 80px;
        }

        span {
            width: 180px;
            height: 90px;
            margin: 20px;
            // box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        }
    }
}

.follow-us-in {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

}

@media only screen and (max-width: 900px) and (min-width: 300px) {
    .cards {
        width: 90% !important;
    }

}

@media only screen and (max-width: 1165px) and (min-width: 901px) {
    .cards {
        width: 47% !important;
    }

}

@media only screen and (max-width: 390px) and (min-width: 300px) {
    .dashboard-cards {
        img {
            width: 90% !important;
        }
    }

    .p-carousel .p-carousel-content .p-carousel-prev,
    .p-carousel .p-carousel-content .p-carousel-next {
        display: none
    }

}