.footer {
    height: 50px;
    background-color: #111;
    margin-bottom: 4px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
   p{
    font-size: 12px;
    margin-right: 30px;
   }
    .scrolltotop {
        width: 35px;
        height: 35px;
        background: red;
        margin: 0px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}